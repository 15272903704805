import { BaseDto } from '@/shared/dtos/base-dto';

export class geo_loc_fichaje extends BaseDto {
    public ip !: string;
    public pais !: string;
    public ciudad !: string;
    public latitud !: string;
    public longitud !: string;
    public isp !: string;
    public entrada !: boolean;
    public id_fichaje !: number;
    public id_usuario  !: number;
    public nombre_usuario !: string;
    public fecha !: string;
 } 
